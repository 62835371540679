:root{
    --violet: #774DEF;
    --indigo: #3E318F;
    --success: #4BB543;
    --failure: #ff3333;
    --info: #ffb242;
    --lightGray: #f6f6f6;
}

p{
    margin: 0;
}

.ms-bolder{
    font-weight: 600 !important;
    opacity: 0.9;
}

.app-cnt{
    margin: auto;
    width: 90%;
    max-width: 1400px;
}

.header-cnt{
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.qo-logo{
    /* width: 300px; */
    height: 38px;
}

.timer-font{
    font-size: 22px;
    font-weight: 600;
    color: var(--violet);
}

.timer-msg-font{
    font-size: 14px;
    font-weight: 500;
    color: var(--violet);
    
}

.textRight{
    text-align: right;
}

.link{
    color: var(--violet);
    cursor: pointer;
}

.link:hover{
    text-decoration: underline;
}

.qo-icon{
    color: var(--violet);
}

.ms-icons{
    font-size: 20px;
}

.success{
    color: var(--success);
}

.failure{
    color: var(--failure);
}

.info{
    color: var(--info);
}

.icon-desc-cnt{
    display: flex;
    margin: 30px 0;
}

.icon-desc{
    margin-right: 20px;
}

.status-item{
    margin-bottom: 10px;
    display: flex;
    align-items: center;
}

.status-item .ms-icons{
    margin: 0 10px;
}


.column-heading{
    color: var(--indigo) !important;
    font-weight: 600;
    font-size: 20px !important;
    border-bottom: 3px solid rgba(224, 224, 224, 1) !important;
}

.clock-menu{
    padding: 10px;
}

.sticky-col{
    position: sticky;
    left: 0;
    background: white;
    z-index: 101;
}

.bg-colored{
    background: var(--lightGray);
}

/* @media (max-width:1200px) and (min-width: 546px){
    .qo-logo{
        width: 300px;
        height: 50px;
    }

    .timer-font{
        font-size: 14px;
        text-align: right;
    }

    .timer-msg-font{
        font-size: 12px;
    }
} */

@media (max-width:1200px) and (min-width: 768px){
    .qo-logo{
        /* width: 300px; */
        height: 38px;
    }

    .column-heading{
        font-size: 18px !important;
    }
}

@media (max-width:767px){
    .qo-logo{
        /* width: 250px; */
        height: 34px;
    }

    .timer-font{
        font-size: 18px;
        text-align: right;
    }

    .icon-desc-cnt{
        flex-direction: column;
    }

    .icon-desc{
        margin-bottom: 15px;
    }
    
    .column-heading{
        font-size: 18px !important;
    }

    .sticky-col{
        background: white;
    }
}